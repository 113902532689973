.root {
    padding: var(--space-size);
}

.loaderContainer {
    width: 100%;
    min-height: 50vh;
    display: flex;
    height: 100%;
}
