.component {
    height: 36px;
    padding: 6px 16px;
    /* background-color: var(--bg-color-primary) !important; */
}

.label {
    margin-right: 8px;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: var(--text-color-mutted);
}

.selected {
    font-family: Mulish;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--text-color-mutted);
    margin-right: 15px;
}

.caret {
    color: var(--text-color-mutted);
}
