.table {
    width: 100%;
    color: var(--text-color-default);
    border-collapse: separate;
    border-spacing: 0;
    border-radius: var(--border-radius-sm);
    border-style: hidden; /* hide standard table (collapsed) border */
    border-width: 0;
    /*box-shadow: 0 0 0 1px #666; !* show new borders *!*/
}

.tableHead {
    font-weight: 600;
    color: var(--text-color-mutted);
}

.tableHead th {
    padding-bottom: calc(var(--row-spacing) * 2);
}

.tableHead th:last-child,
.tableRow td:last-child {
    padding-right: 10px;
    text-align: right;
}

.tableRow td {
    padding: var(--row-spacing) 0;
}

.tableRow td:first-child {
    padding-left: var(--row-spacing);
}

.tableRow td:last-child {
    padding-right: var(--row-spacing);
}

.tableRow:nth-child(2n-1) {
    background-color: var(--bg-color-dark-accent);
    border-radius: var(--border-radius-sm);
}

.tableRow:nth-child(2n-1) td:first-child {
    border-bottom-left-radius: var(--border-radius-sm);
    border-top-left-radius: var(--border-radius-sm);
}

.tableRow:nth-child(2n-1) td:last-child {
    border-bottom-right-radius: var(--border-radius-sm);
    border-top-right-radius: var(--border-radius-sm);
}

tbody tr:first-child td:first-child {
    border-bottom-left-radius: 10px;
}

tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

.prizeTitle {
    padding-left: 10px;
}

.prizeTitle h6 {
    margin: 0;
}

.prizes {
    display: flex;
    flex-direction: column;
}

.prizes span {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 5px;
}

.prizes span + span {
    font-weight: normal;
    color: var(--text-color-mutted);
}

.prizeIcon {
    width: 1em;
    height: 1em;
}

.tableRow.burnRow {
    box-shadow: 0 0 0 1px var(--primary-theme-color);
    background-color: var(--bg-color-primary-alpha);
}

.purple {
    color: var(--text-color-accent);
}
