.paragraph {
    height: 18px;
}

.paragraph ul {
    margin-bottom: 0;
}

.title {
    font-size: 30px;
}

.title h3:not(#id) {
    font-size: inherit;
    height: 1em;
    margin-top: 0;
    max-width: 6ch;
    margin-bottom: 5px;
}
