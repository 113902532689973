.component {
	color: #fff;
}

.component :global .ant-modal-content {
	width: 490px;
}

.component :global .ant-modal-body {
	padding: 15px 16px 0 16px;
}

.component button,
.component button:hover {
	margin-bottom: 20px;
}

.numbersBlock {
	max-height: 300px;
	overflow-y: auto;
}

.headerLabel {
	font-family: Mulish;
	font-style: normal;
	font-weight: bold;
	font-size: 21px;
	line-height: 26px;
	color: #ffffff;
	margin-bottom: 15px;
}

.infoItem {
	font-family: Mulish;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	color: #ffffff;
}

.underline {
	text-decoration-line: underline;
	margin-bottom: 8px;
}

.summary {
	background: #2b344b;
	border: 2px solid #344574;
	box-sizing: border-box;
	border-radius: 16px;
	padding: 16px;
	font-family: Mulish;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: #ffffff;
	margin-bottom: 8px;
}

input {
	background: #2b344b;
	/* padding: 16px; */
	border: none;
	outline: none;
	font-family: Mulish;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: #ffffff;
}

.sum {
	display: block;
	font-size: 16px;
	margin-bottom: 12px;
}

.sumSammarry {
	font-size: 14px;
	line-height: 18px;
}

.info {
	margin-bottom: 30px;
}

.infoItem {
	display: block;
	margin-bottom: 8px;
}

.totalPay {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}

.total {
	font-size: 21px;
	line-height: 26px;
	font-weight: bold;
}

.backBtn,
.backBtn:hover {
	background: transparent;
	border: 2px solid #fff;
	border-radius: 100px;
	font-weight: bold;
	font-size: 15px;
	line-height: 19px;
	color: #fff;
	padding: 0 30px;
	margin: 0 auto;
	display: block;
	margin: 25px auto 10px;
}

.row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	background: #212735;
	border-radius: 40px;
	padding: 6px 20px;
	border: 1px solid #374056;
}

.sindleDigit {
	cursor: pointer;
	width: 30px;
	background: none;
}

@media (max-width: 450px) {
	.component :global .ant-modal-content {
		width: 360px;
	}
}
