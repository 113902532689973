@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&family=Rubik:wght@300;400;500;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    margin: 0;
    font-size: 14px;
    font-family: var(--primary-font-family);
    color: var(--text-color-default);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
    transition: background-color 0.5s ease, color 0.5s ease;
}

svg,
svg * {
    transition: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--secondary-font-family);
    color: var(--text-color-default);

    font-style: normal;
    font-weight: bold;
    line-height: 1.5;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
    font-weight: 500;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
}

p,
span {
    font-family: inherit;
    line-height: 1.4;
}

p {
    color: var(--text-color-default);
}

small {
    font-size: 12px;
    color: var(--text-color-default);
}

button {
    font-family: inherit;
}

#root {
    min-height: 100vh;
    background-color: var(--bg-color-secondary);
}

[text-elipsis] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-modal-content {
    background-color: var(--bg-color-primary);
    border-radius: 16px;
    border: 2px solid var(--bg-color-4);
}

.ant-modal-mask {
    background: rgba(0, 0, 0, 0.62);
    backdrop-filter: blur(10px);
}

.ant-modal-body {
    border: none;
}

.ant-modal .ant-modal-footer:empty {
    display: none;
}

.ant-modal .ant-modal-close {
    color: var(--text-color-4);
}

.ant-modal-close-x {
    color: var(--text-color-mutted);
    font-size: 12px;
}

.ant-notification-notice {
    word-break: break-word;
}

.ant-popover-inner-content {
    padding: 0;
}

.ant-popover-inner {
    background: transparent;
}

.ant-popover-arrow {
    display: none;
}

.ant-dropdown-menu {
    background-color: var(--bg-color-primary);
    padding: 0;
    max-height: 250px;
    overflow: auto;
}

.ant-dropdown-menu-item {
    padding: 8px;
    color: var(--text-color-mutted);
    font-size: 16px;
    line-height: 20px;
    min-width: 150px;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: inherit;
}

.ant-dropdown-menu-item:hover {
    background-color: var(--bg-color-4);
    color: var(--text-color-3);
}

.ant-dropdown-menu-item-selected {
    background-color: var(--bg-color-4);
    color: var(--text-color-3);
}

.ant-tabs-tab {
    color: var(--text-color-mutted);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--text-color-15);
}

.ant-tabs-ink-bar {
    background-color: var(--text-color-15);
}

.ant-input,
.ant-input:hover,
.ant-input:active,
.ant-input:focus {
    background: var(--bg-color-primary);
    border: 2px solid var(--bg-color-4);
    box-sizing: border-box;
    border-radius: 16px;
    color: var(--text-color-mutted);
    font-size: 16px;
    line-height: 20px;
}

.ant-input:focus,
.ant-input-focused {
    box-shadow: 0 0 0 2px var(--bg-color-4);
}

.ant-dropdown-trigger,
.ant-dropdown-trigger:hover,
.ant-dropdown-trigger:active,
.ant-dropdown-trigger:focus {
    border-radius: 100px;
    font-weight: 800;
    font-size: 18px;
    line-height: 23px;
    color: var(--text-color-mutted);
    border: none;
    align-items: center;
    text-decoration: none !important;
}

.ant-dropdown-trigger svg,
.ant-dropdown-trigger:hover svg,
.ant-dropdown-trigger:active svg,
.ant-dropdown-trigger:focus svg {
    width: 11px;
    top: 2px;
    position: relative;
}

.ant-switch {
    background-color: var(--text-color-16);
}

.ant-switch-checked {
    background-color: var(--text-color-3);
}

.ant-switch-handle::before {
    background-color: #c2c6cd;
}

.ant-switch-checked .ant-switch-handle::before {
    background-color: #ffffff;
}

.ant-switch-checked .ant-switch-handle:hover::before {
    background-color: #4260a6;
    opacity: 0.4;
    border-radius: 100px;
}

.ant-tooltip-inner {
    background-color: rgba(90, 138, 252, 0.95);
    color: var(--text-color-mutted);
}

.ant-tooltip-arrow-content {
    background-color: rgba(90, 138, 252, 0.95);
}

.ant-pagination-item {
    background-color: transparent;
    border: none;
}

.ant-pagination-item a {
    color: var(--text-color-16);
    text-decoration: none !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    background-color: transparent;
    border: 0;
    color: var(--text-color-16);
}

.ant-pagination-item-active a,
.ant-pagination-item:focus a,
.ant-pagination-item:hover a,
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
    color: var(--text-color-mutted) !important;
}

.ant-modal-content {
    --modal-content-padding: calc(var(--space-size) * 1.5);
    --modal-content-space: 20px;

    width: 100%;
    overflow: scroll;
    border-radius: 32px;
    border-color: transparent;
}

.ant-modal-body {
    padding: var(--modal-content-padding);
}

.ant-modal-content::-webkit-scrollbar {
    display: none;
}

/* .ant-modal-close {
    margin: var(--modal-content-padding) var(--modal-content-padding) 0 0;
} */

.ant-modal-close {
    margin: 15px;
}

.ant-modal-close svg {
    fill: var(--text-color-20);
}

.ant-modal-close-x {
    font-size: 16px;
    font-weight: bold;
    color: var(--text-color-20);
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: 2px solid #353945;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease 0s;
}

.ant-modal-close-x:hover {
    background-color: #353945;
}

@media (max-width: 767px) {
    .ant-modal-close-x {
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-width: 1px;
    }

    .ant-modal-content {
        min-width: calc(100% - 20px);
    }
}

.theme-default .apexcharts-legend-text {
    color: var(--text-color-2) !important;
}

a:hover {
    color: var(--text-color-15);
}

::selection {
    color: #fff;
    background: var(--text-color-12);
}

:root {
    --primary-theme-color: #795ced;
    --bg-color-theme-mutted: #211e47;

    --bg-color-1: #f6f8fa;
    --bg-color-2: #ffffff;
    --bg-color-secondary: #ff4339;
    --bg-color-4: #ebeef8;
    --bg-color-4-1: rgba(90, 138, 252, 0.4);
    --bg-color-5: #f6f8fe;
    --bg-color-6: rgba(170, 175, 179, 0.08);
    --bg-color-7: #ffffff;
    --bg-color-8: #eef769;
    --bg-color-9: #4260a633; /*  check for the light theme*/
    --bg-color-10: #00d26d; /*  check for the light theme*/
    --bg-color-13: #fd8f77;

    --text-color-mutted: #060a0d;
    --text-color-default: var(--primary-theme-color);
    --text-color-accent: #fff;

    --text-color-2: #717980;
    --text-color-3: #5a8afc;
    --text-color-4: #aaafb3;
    --text-color-5: #000;
    --text-color-6: #4f6ae6;
    --text-color-8: #eef769;
    --text-color-9: #7288ea;
    --text-color-10: #fff;
    --text-color-11: #1bc943;
    --text-color-12: #f4772e;
    --text-color-13: #29abb5;
    --text-color-14: #39c399;
    --text-color-15: #ac344e;
    --text-color-15-20p: rgba(172, 52, 78, 0.2);
    --text-color-16: #7f7f94;
    --text-color-17: #fd8f77; /** check for the light theme*/
    --text-color-18: #00d26d; /** check for the light theme*/
    --border-color-1: #e3e4e6;
    --border-color-2: #e3e4e6;
    --border-color-3: #60626826;
    --border-color-4: #6062680f;
    --border-color-5: #4260a6; /* check the color for light theme*/
    --border-color-6: rgba(66, 96, 166, 0.2); /* check the color for light theme*/

    --shadow-color-1: rgba(6, 10, 13, 0.4), 0 8px 16px rgba(113, 121, 128, 0.08);

    --primary-font-family: 'Open Sans', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
    --secondary-font-family: Rubik, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;

    --space-size: 24px;
    --header-height: 61px;
}

.theme-dark {
    --text-color-primary: var(--primary-theme-color);

    --bg-color-primary: #131520;
    --bg-color-secondary: #0a0d14;
    --bg-color-dark-accent: #000001;
    --bg-color-secondary-mutted: #161230;
    --bg-color-theme-mutted: #211e47;

    --danger-color: #ff406e;
    --higlight-secondary-color: #2776EC;
    --bg-color-primary-alpha: rgba(121, 92, 237, 0.1);

    /*--text-color-*/
    --text-color-default: #ffffff;
    --text-color-light: #d7d9e1;
    --text-color-mutted: #757fa2;
    --text-color-accent: var(--primary-theme-color);

    /* borders */
    --border-radius-lg: 8px;
    --border-radius-sm: 4px;

    --ui-mutted-color: #757fa21a;
    --ui-input-border-color: #212844;

    /* numbers colors */
    --color-1: #90c664;
    --color-2: var(--primary-theme-color);
    --color-3: #ff406e;
    --color-4: #dfbe41;
    --color-5: var(--primary-theme-color);
    --color-6: #ab61e6;

    /* numbers backgrounds */
    --bg-color-1: #90c6641a;
    --bg-color-2: #795ced1a;
    --bg-color-3: #ff406e1a;
    --bg-color-4: #dfbe411a;
    --bg-color-5: #795ced1a;
    --bg-color-6: #ab61e61a;

    /* REMOVE: next */

    --bg-color-4-1: #344574;
    --bg-color-5: rgba(79, 106, 230, 0.08);
    --bg-color-6: rgba(147, 148, 150, 0.08);
    --bg-color-7: #31343d;
    --bg-color-8: #fd8f77;
    --bg-color-9: #4260a633;
    --bg-color-10: #00d26d;
    --bg-color-11: #4260a6;
    --bg-color-13: #eef769;

    --text-color-2: #939496;
    --text-color-3: #5a8afc;
    --text-color-4: #606268;
    --text-color-5: #fff;
    --text-color-6: #7288ea;
    --text-color-8: #95a6ef;
    --text-color-10: #fff;
    --text-color-11: #1bc943;
    --text-color-12: #f4772e;
    --text-color-13: #29abb5;
    --text-color-14: #39c399;
    --text-color-15: #eef769;
    --text-color-15-20p: rgba(238, 247, 105, 0.2);
    --text-color-16: #7f7f94;
    --text-color-17: #fd8f77;
    --text-color-18: #00d26d;
    --text-color-19: #c2c6cd;
    --text-color-20: #fcfcfd;

    --border-color-1: #44494d;
    --border-color-2: #ff4339;
    --border-color-3: #60626887;
    --border-color-4: #6062681f;
    --border-color-5: #4260a6;
    --border-color-6: #5a8afc;
    --border-color-7: #c2c6cd;

    --shadow-color-1: rgba(255, 255, 255, 0.4), 0 8px 16px rgba(0, 0, 0, 0.08);
}

.highlighted {
    position: relative;
    z-index: 0;
}

.highlighted::after {
    content: '';
    z-index: -1;
    position: absolute;
    width: 94px;
    height: 94px;
    background-color: var(--primary-theme-color);
    filter: blur(168px);
}

/* FIXME */

/** {*/
/*    scrollbar-color: var(--primary-theme-color) var(--bg-color-theme-mutted);*/
/*    scrollbar-width: thin;*/
/*}*/

/*::-webkit-scrollbar {*/
/*    width: 6px;*/
/*    background-color: var(--bg-color-theme-mutted);*/
/*}*/

/*::-webkit-scrollbar-track {*/
/*    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);*/
/*}*/

/*::-webkit-scrollbar-thumb {*/
/*    background-color: var(--primary-theme-color);*/
/*    border-radius: 1em;*/
/*}*/
