.component {
  position: relative;
}

.wallet {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  width: 100%;
  align-items: center;
  height: var(--header-height);
  background: var(--bg-color-primary)
    linear-gradient(
      to right,
      var(--bg-color-primary) 60%,
      var(--primary-theme-color) 180%
    );
  color: var(--text-color-primary);
}

.rightAlign {
  margin-left: auto;
}

/*@media (max-width: 950px) {*/
/*  .component {*/
/*    !*padding: 15px 15px 18px;*!*/
/*  }*/

/*  .wallet {*/
/*    !* display: inline-block; *!*/
/*    !*padding-right: 10px;*!*/
/*    !*margin-top: 12px;*!*/
/*    !*border-radius: 4px;*!*/
/*  }*/
/*}*/
@media (max-width: 950px) {
  .wallet {
    /* display: inline-block; */
    padding-right: 10px;
    border-radius: 4px;
  }
}
