.container {
    min-height: 100vh;
    flex-direction: inherit;
    background-color: var(--bg-color-secondary);
}

.main {
    position: relative;
    background-color: var(--bg-color-secondary);
}

:global .mobile-menu-open :local .main {
    max-height: 100vh;
    overflow: hidden;
    top: 0;
    position: fixed;
}

:global .ant-menu-item,
:global .ant-menu-submenu-title {
    display: flex;
    align-items: center;
}

:global .ant-menu-submenu-title svg {
    margin-right: 10px;
}

.content {
    padding: var(--space-size);
}

.footer {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    /*justify-content: center;*/
    padding: 20px var(--space-size);
    background-color: var(--bg-color-primary);
    color: var(--text-color-mutted);
    align-items: center;
}

.footer::before {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: -1px;
    height: 1px;
    background-color: var(--text-color-mutted);
    opacity: 0.2;
}

.footer::after {
    left: 0;
    bottom: 0;
    z-index: 0;
}

.center {
    justify-self: center;
}

.logos {
}

.certik {
    display: inline-block;
    max-height: 2em;
}

svg {
    max-height: inherit;
}

.footerLinks {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/*.footerLinks a {*/
/*    margin-left: 24px;*/
/*    font-family: var(--primary-font-family);*/
/*    font-style: normal;*/
/*    font-weight: 600;*/
/*    font-size: 14px;*/
/*    line-height: 24px;*/
/*    color: var(--text-color-4);*/
/*}*/

.siderNav {
    background-color: var(--bg-color-secondary);
    border-right: 1px solid var(--bg-color-primary);
}

.siderNav svg {
    width: 30px;
    margin-right: 10px;
}

:global
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
.ant-menu-item-selected {
    background: transparent;
    position: relative;
    overflow: revert;
}

:global
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
.ant-menu-item-selected::after {
    border-left: 1px solid var(--primary-theme-color);
    left: 0;
    top: 0;
    height: 100%;
    width: 80px;
    background: linear-gradient(
            90deg,
            rgba(97, 76, 188, 0.34) 0%,
            rgba(121, 92, 237, 0) 100%
    );
    transform: perspective(50px) rotateY(340deg);
}

.siderNav > div {
    position: fixed;
    width: inherit;
}

.siderNav ul {
    background: var(--bg-color-secondary) !important;
    height: calc(100% - 215px);
    overflow-y: auto;
    border-right: 1px solid var(--bg-color-primary) !important;
}

.siderNav li {
    display: flex;
    align-items: center;
    color: var(--text-color-mutted);
}

.siderNav .submenu ul {
    overflow-y: hidden;
}

.siderNav .submenu span {
    width: 100px;
    opacity: 1;
    transition: 0.2s;
}

.collapsedX li {
    padding: 0 7px !important;
    justify-content: center;
}

.collapsedX svg {
    margin-right: 0;
}

.collapsedX .submenu div {
    padding: 0 7px !important;
    justify-content: center;
    transition: 0.5s;
}

.collapsedX .submenu span {
    width: 0;
    opacity: 0;
    /* transition: 0.2s; */
}

.collapsedX .logo svg {
    width: 45px;
}

.collapsedX .collapse {
    display: none;
}

.siderNav .submenu {
    display: block;
}

.siderNav .submenu div {
    display: flex;
    align-items: center;
}

.siderNav .submenu ul li {
    padding-left: 64px !important;
}

.titleDivider {
    font-family: var(--secondary-font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #606a8c;
    padding: 30px 24px 20px !important;
    display: block;
}

.collapsedX .titleDivider {
    padding: 30px 10px 20px !important;
    text-align: center;
}

.header {
    display: flex;
    align-items: center;
    backdrop-filter: opacity(0.5);
    padding: 10px 0;
    height: var(--header-height);
    justify-content: center;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: var(--bg-color-primary);
}

.logo {
    /*padding: 30px 20px;*/
}

.header svg {
    min-width: 130px;
}

.header.collapsed svg {
    min-width: 40px;
}

.collapse {
    cursor: pointer;
}

.themeToggle {
    position: absolute;
    bottom: 90px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.collapsedX .themeToggle {
    bottom: 73px;
}

.siderNav ul::-webkit-scrollbar {
    width: 10px;
}

.siderNav ul::-webkit-scrollbar-track {
    background-color: transparent;
}

.siderNav ul::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #0fc0cb;
    border-radius: 4px;
}

.tokenMenuItem {
    display: flex;
    align-items: center;
}

.tokenImageWrapper {
    width: 1em;
    height: 1em;
    margin-right: 1ch;
}

.tokenImage {
    object-fit: contain;
    height: 100%;
    width: auto;
}

/* RESPONSIVE */
@media (max-width: 768px) {
    .siderNav {
        display: none;
    }

    .content {
        padding: 0 8px 20px;
    }

    .footer {
        display: none;
    }
}
