.component :global .ant-modal-content {
  max-width: 490px;
}

.prizes {
  --border: 1px solid var(--ui-mutted-color);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--modal-content-space) 0;
  border-top: var(--border);
  border-bottom: var(--border);
  margin-bottom: var(--modal-content-space);
  color: var(--text-color-mutted);
}

.prize,
.small {
  margin-bottom: 0;
  text-align: right;
}

.prize {
  font-weight: 500;
}

.small {
  color: var(--text-color-mutted);
}

.round {
  font-weight: 500;
  text-align: center;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}
.button {
  width: 100%;
}

.button .buttonTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

@media (max-width: 450px) {
  .component :global .ant-modal-content {
    max-width: 360px;
  }
}
