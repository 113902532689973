.root {
    background-color: var(--ui-mutted-color);
    border: 1px solid var(--text-color-mutted);
    font-weight: bold;
    display: flex;
    padding: 4px;
    border-radius: var(--border-radius-sm);
    margin-bottom: var(--row-spacing);
}

.number {
    flex: 1;
    text-align: center;
}

.outlined {
    border-color: var(--color-1);
}

.winning {
    background-color: var(--primary-theme-color);
    border-color: var(--primary-theme-color);
}

.winNumber {
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-1);
    color: var(--color-1);
}

.outlined > .winNumber {
    border-color: transparent;
}

.winNumber:not(:first-child) {
    border-left-width: 0;
}

.winNumber:not([data-selected='true']) {
    border-right-width: 0;
}

.title {
    color: var(--text-color-mutted);
    margin-bottom: var(--row-spacing);
}
