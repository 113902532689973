.section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 50px;
}

.description {
  color: var(--text-color-mutted);
  position: relative;
  max-width: 500px;
  margin: auto;
}

.description:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -20px;
  width: 106px;
  height: 2px;
  left: calc(50% - 53px);
  background: #795ced;
  right: 50%;
}

.image {
  flex: 0 1 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialsBox {
  flex: 1 1 100%;
  align-items: center;
  justify-content: center;
}
.socials {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 250px;
  margin: auto;
}

.socials a {
  color: #795ced;
  width:  25px;
  height: 25px;
}

.socials svg {
  width: 25px;
  height: 25px;
}

.bottomInfo {
  margin: 25px 0 100px;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
}

.bottomInfo a {
  color: #ffffff;
}

@media (max-width: 768px) {
  .section {
    margin-top: 20px;
  }
  .image img {
    max-height: 140px;
  }
  .description:after {
    bottom: 0px;
  }
  .bottomInfo {
    margin: 15px 0;
  }
}
