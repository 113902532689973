.table {
    --row-spacing: calc(var(--space-size) / 1.2);
    --row-padding: var(--row-spacing);

    width: 100%;
    color: var(--text-color-default);
    min-width: 100%;
    border-collapse: separate;
    border-spacing: 0 var(--row-spacing);
}

.tableHead th {
    color: var(--text-color-mutted);
    padding: var(--row-padding);
    text-transform: uppercase;
    position: sticky;
    top: 0;
    background-color: var(--bg-color-secondary);
    z-index: 1;
}


.tableRow {
    background-color: var(--bg-color-primary);
    margin-bottom: var(--row-spacing);
}

.tableRow td {
    padding: var(--row-padding);
    position: relative;
}

.tableRow td:not(:last-child)::before {
    --position: calc(var(--row-spacing, 20px) / 2);
    content: '';
    position: absolute;
    width: 1px;
    top: var(--position);
    bottom: var(--position);
    right: 0;
    display: block;
    background-color: var(--ui-mutted-color);
}

.empty {
    padding: var(--space-size) 0;
}
