.root {
    --row-spacing: calc(var(--space-size) / 1.2);
    --row-padding: var(--row-spacing)
}

.tableContainer {
    width: 100%;
    overflow: auto;
    max-height: 50vh;
}

.table {
    min-width: 100%;
    border-collapse: separate;
    border-spacing: 0 var(--row-spacing);
}

.tableHead th {
    color: var(--text-color-mutted);
    padding: var(--row-padding);
    text-transform: uppercase;
    position: sticky;
    top: 0;
    background-color: var(--bg-color-secondary);
    z-index: 1;
}

.tableHead th:last-child {
    text-align: center;
}

.tableRow {
    background-color: var(--bg-color-primary);
    margin-bottom: var(--row-spacing);
}

.tableRow td {
    padding: var(--row-padding);
    position: relative;
}

.tableRow td:not(:last-child)::before {
    --position: calc(var(--row-spacing, 20px) / 2);
    content: '';
    position: absolute;
    width: 1px;
    top: var(--position);
    bottom: var(--position);
    right: 0;
    display: block;
    background-color: var(--ui-mutted-color);
}

.tableRow td:first-child {
    border-top-left-radius: var(--border-radius-lg);
    border-bottom-left-radius: var(--border-radius-lg);
}

.tableRow td:last-child {
    border-top-right-radius: var(--border-radius-lg);
    border-bottom-right-radius: var(--border-radius-lg);
}

.network {
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.icon {
    display: flex;
}

.network svg {

    --size: var(--space-size);

    height: var(--size);
    width: var(--size);
    margin-right: calc(var(--row-spacing) / 2);
}

@media screen  and (max-width: 1000px) {
    .root {
        --row-padding: calc(var(--row-spacing) / 4)
    }

    .date {
        flex-wrap: wrap;
        justify-content: center;
    }

    .network {
        flex-direction: column;
        justify-content: center;
    }

    .network svg {
        --size: 1em;
    }
}
