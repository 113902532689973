/*
.overlay :global .ant-popover-inner-content {
    background-color: #181b29;
    border: 2px solid rgba(90, 138, 252, 0.4);
    border-radius: 16px;
}
*/
.component {
  display: flex;
  gap: calc(var(--space-size) / 2);
}

.disable {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.3;
}

.overlay {
    min-width: 170px;
    max-width: 200px;
    background-color: var(--bg-color-primary);
    border: 1px solid var(--primary-theme-color);
    border-radius: 8px;
}

.overlay :global .ant-popover-arrow {
    border-color: var(--primary-theme-color) !important;
}

.notificationBtn {
    cursor: pointer;
    color: var(--text-color-mutted);
    margin-top: 8px;
    margin-right: 12px;
}

.walletBox {
    display: flex;
    flex-direction: row;
}

.divider {
    border: 1px solid var(--primary-theme-color);
    transform: rotate(180deg);
    height: 30px;
}

.walletPreview {
    cursor: pointer;
    padding: 7px 0;
    background-color: var(--bg-color-primary-alpha);
    border: 1px solid var(--primary-theme-color);
    border-radius: 8px;
    width: 170px;
    display: flex;
    justify-content: center;
}

.walletPreviewAvatar {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 4px;
}

.walletPreviewHash {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--text-color-15);
}

.walletPreviewHashOnBtn {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
}

.walletPreviewArrow {
    width: 10px;
    height: 10px;
    margin-left: 10px;
    transform: rotate(180deg);
}

.walletAvatarCol {
    color: #eef769;
}

.walletHash {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #4f6ae6;
}

.walletNavegation {
    margin: 24px 16px 0 16px;
}

.walletNavegation a {
    text-decoration: none !important;
    font-size: 16px;
    line-height: 20px;
    color: var(--text-color-mutted);
}

.iconCol {
    display: flex !important;
    justify-content: flex-end !important;
    margin: auto !important;
}

.poligonIcon {
    width: 13px;
    height: 8px;
    margin-top: 1px;
    color: var(--text-color-mutted);
}

.navegationName {
    flex-grow: 1;
    font-size: 16px;
    line-height: 20px;
    color: var(--text-color-mutted);
    cursor: pointer;
}

.etherumCoin {
    flex-grow: 1;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: var(--text-color-mutted);
    margin: auto;
}

.disconnectBtnRow {
    padding: 4px 16px 4px;
    justify-content: center;
    background-color: var(--bg-color-primary-alpha);
}

.disconnectBtn, .disconnectBtn:hover {
    border-color: transparent;
    background-color: transparent;
}

.disconnectBtn:hover {
    color: var(--text-color-default);
}

.hidden {
    visibility: hidden;
}

.margin {
    margin-right: 2rem;
}

.connectorList {
    margin: 8px -4px;
    display: flex;
    flex-wrap: wrap;
}

.connectorBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    background: var(--bg-color-2);
    border: 2px solid var(--bg-color-4);
    box-sizing: border-box;
    border-radius: 8px;
    margin: 4px;
    flex: 1;
}

.connectorBtn svg {
    width: 40px;
    max-height: 40px;
    max-width: 45px;
}

.connectorBtn:hover {
    border: 2px solid var(--border-color-2);
    box-shadow: 0 16px 32px rgba(255, 67, 57, 0.04);
}

.connectorBtn:disabled,
.connectorBtn[disabled]:hover {
    background-color: var(--bg-color-6) !important;
    opacity: 0.5;
    border: 2px solid var(--border-color-4);
}

.connectorLogo {
    width: 32px;
    height: 32px;
}

.connectorName {
    margin-left: 6px;
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
    color: var(--text-color-mutted);
}

.avatar {
  border-radius: var(--border-radius-lg);
    border: 1px solid var(--ui-mutted-color);
    background-color: var(--bg-color-primary-alpha);
}

@media (max-width: 500px) {
    .connectBtn {
        width: 140px;
    }

    .walletPreviewHash {
        font-size: 12px;
        line-height: 24px;
    }
}

@media (max-width: 950px) {
    .walletPreview {
        height: 45px;
        min-height: 1px;
    }

    .notificationBtn {
        margin-top: 15px;
    }

    .walletBox {
        justify-content: flex-end;
        align-items: center;
    }
}
