.socials {
    --social-links-gap: var(--space-size);

    display: flex;
    gap: var(--social-links-gap);
    justify-content: space-between;
    flex-wrap: wrap;
    width: max-content;
    max-width: 100%;
    align-items: center;
    color: var(--text-color-mutted);
}

.socialLink {
    display: inline-block;
    width: 18px;
    height: 18px;
    color: inherit;
}

.socialLink:hover {
    opacity: 0.6;
}
