.cardBody {
  min-height: 200px;
  min-width: 200px;
  padding: var(--space-size);
  width: max-content;
  position: relative;
}

.cardBody:after {
  content: '';
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  background: #795ced;
  filter: blur(70px);
  z-index: 20;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0%);
}

@media (max-width: 1024px) {
  .cardBody {
    padding: 0 10px;
  }
}

@media (max-width: 600px) {
  .cardBody {
    margin: 0 auto;
    padding: 10px;
  }
}
