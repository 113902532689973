.time {
    font-family: var(--primary-font-family);
    font-weight: 800;
    font-size: 36px;
    line-height: 53px;
    color: #0fc0cb;
    margin-right: 10px;
}

.timer {
    display: flex;
    font-size: 21px;
    color: var(--text-color-default);
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.timerContainer {
    margin: 10px 0;
    display: flex;
}

.timerPart {
    display: flex;
    align-items: center;
    gap: 4px;
}

.wrapper {
    --font-size: 18px;
    font-size: var(--font-size);
    display: flex;
    height: 54px;
    width: calc(var(--font-size) * 2.5);
    line-height: 1.2;
    background-color: var(--bg-color-primary);
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.wrapper.ltr {
    --font-size: 14px;
    background: transparent;
}
