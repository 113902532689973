.section {
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #795ced;
    box-sizing: border-box;
    padding: 55px 0 40px;
    background: var(--bg-color-primary) url('../../../../resources/images/lottery/jackpotBackground.png') no-repeat center / cover;
}

.info {
    text-align: center;
}

.info img {
    margin-bottom: 28px;
}

.sum {
    width: max-content;
    height: 68px;
    background: rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 2em rgba(121, 92, 237, 0.57);
    border-radius: 9px;
    display: flex;
    align-items: stretch;
    margin: 0 auto 55px;
    overflow: hidden;
}

.jackpotChar {
    display: inline-flex;
    width: calc(var(--space-size) * 2);
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-color: var(--bg-color-primary-alpha);
    border-width: 4px 1px;
    letter-spacing: 0;
}

.jackpotChar:first-child {
    border-left-width: 4px;
}

.jackpotChar:last-child {
    border-right-width: 4px;
}

.description {
    max-width: 450px;
    margin-bottom: 30px;
}

.description span {
    color: var(--text-color-accent);
    font-weight: bold;
}

.partipians {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.playButton {
    display: inline-block;
}

.person {
    margin-left: -8px;
    height: 24px;
}

.person img, .person span.noImage {
    width: 24px;
    height: 24px;
    border: 1px solid #1a1d2c;
    box-sizing: border-box;
    border-radius: 50%;
}

.person span.noImage {
    display: inline-block;
    line-height: 24px;
    font-size: 10px;
    background-color: var(--bg-color-primary);
}

.counter {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #90c664;
    margin-left: 5px;
}

@media (max-width: 768px) {
    .info img {
        object-fit: contain;
        max-height: 40px;
    }

    .sum {
        justify-content: center;
        letter-spacing: 20px;
        margin-bottom: 30px;
        width: 95%;
    }
}
