.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--space-size);
}

.info {
  color: var(--text-color-mutted);
}

.infoLinks {
  display: flex;
  justify-content: center;
  gap: var(--space-size);
}

.soon {
  max-width: 150px;
}

.themed {
  color: var(--text-color-accent);
}

.left,
.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--space-size);
}

:is(.left, .right) :is(p, small) {
  display: block;
  color: var(--text-color-light);
}

:is(.left, .right) h3 {
  color: var(--text-color-accent);
  position: relative;
}

:is(.left, .right) > div {
  max-width: 300px;
}

.svg {
  position: absolute;
  top: 50%;
}

.left {
  text-align: right;
}

.right {
  text-align: left;
}

.left .svg {
  left: calc(100% + var(--space-size) / 2);
}
.right .svg {
  right: calc(100% + var(--space-size) / 2);
}

.svgRev {
  top: -50%;
}

.imageBlock {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr auto 1fr;
  gap: calc(var(--space-size));
  margin-top: var(--space-size);
}

@media screen and (max-width: 600px) {
  .imageBlock {
    display: block;
  }
  .imageBlock img {
    display: none;
  }
}
