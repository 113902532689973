.component {}

.component :global .ant-modal-body {
    padding: 15px 16px;
}

.headerLabel {
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 26px;
    color: var(--text-color-3);
}

.text {
    margin-top: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: var(--text-color-2);
}

.footer {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
}

.switchBtn, .switchBtn:hover {
    padding: 6px 32px;
    background: var(--text-color-3);
    border-radius: 100px;
    color: var(--text-color-mutted);
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    border: none;
    margin-right: 20px;
    transition: none;
}

.backBtn, .backBtn:hover {
    background: transparent;
    border: 2px solid var(--text-color-3);
    border-radius: 100px;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: var(--text-color-3);
}
