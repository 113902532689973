.root {
    margin-top: var(--space-size);
}

.root :global(.ant-tabs-nav::before) {
    border-bottom-color: var(--ui-mutted-color);
}

.root :global(.ant-tabs-tab) {
    padding-bottom: var(--space-size);
}

.root :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
    color: var(--text-color-default);
}

.root :global .ant-tabs-ink-bar {
    background-color: var(--primary-theme-color);
    height: 1px !important;
    bottom: 0;
}

.tabContent {
    color: var(--text-color-default);
    position: relative;
}
