.wrapper {
    --container-padding: 20px;

    /*flex: 1 1 41%;*/
    position: relative;
    box-sizing: border-box;
    padding: var(--container-padding);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 600px;
    border-radius: var(--border-radius-lg);
    box-shadow: 0 0 4px var(--primary-theme-color);
    min-width: 300px;
    background: radial-gradient(
            50% 50% at 50% 50%,
            var(--bg-color-theme-mutted) 0%,
            var(--bg-color-secondary-mutted) 100%
    );
}

.partnerLink {
    position: absolute;
    left: var(--container-padding);
    top: var(--container-padding);
}

.hero {
    width: 100%;
    background-image: url('./../../../../resources/images/circle-flares.png');
    background-repeat: no-repeat;
    background-position: top center;
    color: var(--text-color-default);
}

.hero .token .heroIcon {
    height: 28px;
    width: auto;
}

.hero .prizeIcon {
    height: 28px;
    width: auto;
}

.token {
    height: 25px;
    text-align: center;
    margin-bottom: var(--space-size);
}

.wrapper svg {
    height: 100%;
}

.title {
    color: inherit;
    text-align: center;
}

.title span {
    /* text-transform: lowercase; */
    display: inline-block;
}

.title span::first-letter {
    text-transform: capitalize;
}

.prizeTitle {
    text-align: center;
    font-size: 36px;
    color: inherit;
    margin-bottom: 0;
}

.titleSmall {
    text-align: center;
}

.subTitle {
    text-align: center;
    color: inherit;
}

.timer {
    color: inherit;
    text-align: center;
}

.thin {
    font-weight: normal;
}

.contentWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(15% + var(--container-padding));
    gap: minmax(auto , var(--container-padding)) 0;
    display: flex;
    padding-left: 10px;
    flex-direction: column;
    width: calc(85% - var(--container-padding) * 4);
    justify-content: space-evenly;
    /*transform: translateY(-50%) translateX(-40%);*/
}

.ticketWrapper {
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    padding: 0 var(--container-padding);
}

.ticket {
    width: 100%;
}

.ticketTitle {
    font-size: 21px;
    margin-bottom: 0;
    color: inherit;
}

.innerCont {
    color: inherit;
    margin-bottom: 0;
}

.innerCont > span:first-child {
    margin-right: 10px;
}

.middle {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.ticketsQty {
    color: var(--text-color-accent);
    margin: 0 1ex;
    cursor: pointer;
}

.footer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.footer::after {
    bottom: -50%;
}

.footer > .socials {
    margin: var(--space-size);
    color: var(--text-color-accent);
}

.buy {
    font-weight: bold;
    padding: 8px 34px;
    height: auto;
    background-color: var(--primary-theme-color);
    border: 0;
    color: inherit;
}

.prizeCont {
    font-size: 24px;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 4px;
}

.prizeCont svg {
    height: 0.9em;
    width: 0.9em;
}

.prize {
    color: var(--text-color-accent);
}

.prizeUsd {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
}

.small {
    font-family: var(--primary-font-family);
    font-weight: normal;
    font-size: 14px;
    line-height: 10px;
    color: #7f7f94;
}

.usersTicketsTitle {
    font-family: var(--primary-font-family);
    font-weight: normal;
    font-size: 14px;
    line-height: 10px;
    color: #7f7f94;
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
}

.action {
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    color: #0fc0cb !important;
    cursor: pointer;
    font-weight: bold !important;
    font-size: 16px !important;
}

.center {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1100px) {
    .wrapper {
        order: -1;
    }
}

@media screen and (max-width: 800px) {
    .wrapper {
        --container-padding: 10px;
    }

    .contentWrapper {
        padding: 0;
    }
}

@media screen and (max-width: 680px) {
    .contentWrapper {
        justify-content: center;
    }
}


@media screen and (max-width: 600px) {
    .ticketTitle {
        font-size: 16px;
    }

    .middle {
        display: block;
    }

    .ticketWrapper {
        padding: 0;
    }
}
