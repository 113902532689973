.section {
  font-family: Poppins, sans-serif;
}

.title {
  font-family: inherit;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  color: var(--text-color-default);
  text-align: center;
  margin-bottom: 30px;
}

.section .title {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: left;
  margin-top: 40px;
}

.columnTitle {
  font-family: inherit;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: var(--text-color-mutted);
  margin-bottom: 30px;
}

.rulesWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-size);
  align-items: stretch;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  .rulesWrapper {
    flex-direction: column;
  }
}

.description {
  color: var(--text-color-mutted);
  position: relative;
  max-width: 500px;
  margin: auto;
}

.description:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -20px;
  width: 106px;
  height: 2px;
  left: calc(50% - 53px);
  background: #795ced;
  right: 50%;
}

.stepItem {
  font-family: inherit;
  box-sizing: border-box;
  border-radius: 40px;
  padding: 15px 40px;
  color: var(--text-color-mutted);
  flex: 1;
  background: #131520;
  border-radius: 8px;
  text-align: center;
  position: relative;
}

.stepItem:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 1px;
  width: 94%;
  height: 2px;
  left: 3%;
  background: #795ced;
}

.stepNumber {
  margin: 20px 0;
  display: block;
  font-family: Open Sans;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #795ced;
  text-transform: capitalize;
}

.stepTitle {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
  display: block;
  color: var(--text-color-default);
  font-family: Rubik;
  text-align: center;
}

.stepDescription {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: block;
  font-family: Open Sans;
  text-align: center;
  color: #757fa2;
}
