.root {
    --spacing: calc(var(--space-size) / 1.2);
    --gradient: linear-gradient(93.11deg, var(--bg-color-primary) 9.48%, var(--bg-color-theme-mutted) 75.96%);
    --icon-size: calc(var(--space-size) * 1.5);

    padding-bottom: min(calc(var(--spacing) * 4), 10vw);
}

.header, .subscribe {
    background: var(--bg-color-primary) var(--gradient);
    border-radius: var(--border-radius-lg);
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
}

.info {
    padding: var(--space-size);
    position: relative;
    z-index: 1;
}

.imageContainer {
    position: relative;
}

.higLight {
    position: absolute;
    filter: blur(168px);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--higlight-secondary-color);
    z-index: 0;
}

.heroImage {
    position: relative;
    height: 100%;
}

.links {
    display: flex;
    flex-direction: column;
    gap: var(--spacing);
}

.link {
    display: flex;
    align-items: center;
    color: var(--text-color-default);
    gap: calc(var(--spacing) / 2);
}

.linkIcon {
    display: flex;
    height: var(--icon-size);
    width: var(--icon-size);
    align-items: center;
    justify-content: center;
    border-radius: var(--icon-size);
    border: 2px solid currentColor;
}

.linkIcon svg {
    height: var(--spacing);
    width: var(--spacing);
}

.text {
    padding: calc(var(--spacing) * 3) 0;
    max-width: 900px;
}

.text h3:not(:first-child) {
    margin-top: calc(var(--spacing) * 1.5);
}

.text p {
    color: var(--text-color-mutted);
}

.subscribe {
    justify-content: center;
    flex-direction: column;
    padding: var(--space-size);
    align-items: center;
}

.subscribe h3 {
    margin-bottom: calc(var(--spacing) * 1.5);
}

.subscribe h4 {
    margin-bottom: var(--spacing);
}

.inputContainer {
    display: flex;
    width: 100%;
    align-items: center;
    max-width: 550px;
    gap: var(--spacing);
    margin-bottom: calc(var(--spacing) * 2);
}

.inputContainer > div {
    margin-bottom: 0;
}

.socialLink {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--icon-size);
    height: var(--icon-size);
    color: var(--text-color-accent);
    border-radius: calc(var(--border-radius-lg) / 1.5);
    background-color: var(--ui-mutted-color);
}

@media screen and (max-width: 1000px) {
    .imageContainer {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
    }

    .imageContainer img {
        display: none;
    }
}

@media screen and (max-width: 650px) {
    .root {
        --spacing: var(--space-size);
    }

    .inputContainer {
        flex-wrap: wrap;
    }

    .subscribeBtn {
        width: 100%;
    }
}
