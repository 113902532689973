.component {
    color: var(--text-color-mutted);
    max-width: 450px;
}


.component button,
.component button:hover {
    margin-bottom: 20px;
}

.infoItem {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 19px;
    color: var(--text-color-mutted);
    text-decoration: none;
}

.infoItem strong {
    color: #fff;
}

.infoItem:first-child {
    color: var(--text-color-mutted);
}

.infoItem:last-child {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    font-family: var(--secondary-font-family);
    color: #fff;
}

.infoItemInfo {
    cursor: pointer;
    margin-left: 5px;
}

.wrapper {
}

.underline {
    text-decoration-line: underline;
    margin-bottom: 8px;
}

.summary {
    background: var(--bg-color-secondary);
    border: 1px solid var(--primary-theme-color);
    border-radius: var(--border-radius-lg);
    padding: 12px 14px;
    font-style: normal;
    color: var(--text-color-default);
    margin-bottom: 10px;
    text-align: right;
}

.danger {
    color: var(--danger-color);
    border-color: inherit;
}

input {
    background: inherit;
    /* padding: 16px; */
    border: none;
    outline: none;
    font-style: normal;
    color: inherit;
}

.sum {
    font-family: var(--secondary-font-family);
    display: block;
    margin-bottom: 5px;
    text-align: right;
    width: 100%;
    background: transparent;
}

.sumSammarry {
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    opacity: 0.3;
    font-weight: 400;
}

.balance {
    text-align: right;
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 16px;
    color: var(--text-color-mutted);
}

.info {
    margin-bottom: 30px;
}

.buyTicketWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.buyTicketWrap .infoItem {
    color: #fff;
    margin-bottom: 0;
}

.buyTicketWrap .infoItem:last-child {
    font-family: var(--secondary-font-family);
    font-weight: 500;
    display: flex;
    align-items: center;
}

.buyTicketWrap .infoItem:last-child img {
    margin-left: 5px;
}

.totalPay {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.totalPay:first-child,
.totalPay:last-child {
    border-top: 1px solid rgba(117, 127, 162, 0.1);
    padding-top: 20px;
}

span.buttonTitle {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.walletInfo {
    color: var(--text-color-mutted);
}

@media (max-width: 450px) {
    .component :global .ant-modal-content {
        width: 360px;
    }
}
