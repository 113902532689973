.topInfo {
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.round {
    display: flex;
    gap: 10px;
    align-items: center;
}

.setNumber {
    color: var(--color-1);
    border: 1px solid var(--color-1);
    padding: 4px 10px;
    max-width: 45px;
    text-align: center;
    background-color: var(--bg-color-1);
    border-radius: var(--border-radius-sm);
}

.title {
    color: inherit;
    margin: 0;
}

.controls {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.controls button {
    background-color: transparent;
    cursor: pointer;
    border-radius: 100%;
    height: 18px;
    width: 18px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: var(--text-color-mutted);
    color: var(--text-color-mutted);
}

.controls button svg {
    font-size: 18px;
}

.controls button[disabled] svg path {
    /*fill: #57595c;*/
}

.controls button:hover,
.controls button:active,
.controls button:focus {
    background-color: transparent;
    cursor: pointer;
    border-color: var(--text-color-primary);
    color: var(--text-color-primary);
    /*color: #fff;*/
}

.controls button[disabled],
.controls button[disabled]:hover,
.controls button[disabled]:active,
.controls button[disabled]:focus {
    background-color: transparent;
    cursor: not-allowed;
    border-color: var(--text-color-primary);
    color: var(--text-color-primary);
    opacity: 0.2;
}

.section .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: left;
    margin-top: 40px;
}

.winNumbers {
    margin-top: var(--space-size);
}

.numbers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: var(--secondary-font-family);
    font-size: 18px;
}

.row {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.row._1 {
    color: var(--color-1);
    border-color: var(--color-1);
    background-color: var(--bg-color-1);
}

.row._2 {
    color: var(--color-2);
    border-color: var(--color-2);
    background-color: var(--bg-color-2);
}

.row._3 {
    color: var(--color-3);
    border-color: var(--color-3);
    background-color: var(--bg-color-3);
}

.row._4 {
    color: var(--color-4);
    border-color: var(--color-4);
    background-color: var(--bg-color-4);
}

.row._5 {
    color: var(--color-5);
    border-color: var(--color-5);
    background-color: var(--bg-color-5);
}

.row._6 {
    color: var(--color-6);
    border-color: var(--color-6);
    background-color: var(--bg-color-6);
}
