.text {
    color: var(--text-color-mutted);
}

.buttons {
    display: flex;
    flex-wrap: wrap;
    margin: var(--modal-content-space) 0;
    gap: var(--modal-content-space);
}
