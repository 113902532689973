.section {
  padding: 20px 10px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  position: relative;
  flex-wrap: nowrap!important;
  background: #131520;
  border-radius: 8px;
  overflow: hidden;
}

.person {
  margin: 0 10px!important;
  display: block;
  align-items: center;
  justify-content: space-between;
  vertical-align: middle;
}

.person img {
  margin-right: 10px;
}

.info {
  display: inline-block;
  height: 42px;
}

.address {
  font-family: Open Sans;
  font-size: 14px;
  line-height: 19px;
  color: #795ced;
  margin-bottom: 5px;
}

.sum {
  font-family: Open Sans;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.sum svg {
  margin-left: 5px;
}

.avatar {
  height: 42px!important;
  width: 42px!important;
  line-height: 42px !important;
  border-radius: 4px;
  margin-right: 10px;
  margin-top: -24px;
}

.avatar span {
  line-height: 42px !important;
}

.currency{
  margin-left: 6px;
  margin-bottom: -3px;
}

@media (max-width: 1350px) {
  .section {
    flex-wrap: wrap;
    justify-content: center;
  }
  .person {
    margin: 0 10px 20px;
  }
}

@media (max-width: 900px) {
  .section {
    display: none;
  }
}
