.root {
    --max-width: 600px;
}

.header {
    max-width: var(--max-width);
    display: flex;
    gap: var(--space-size);
    margin: calc(var(--space-size) * 3) 0;
}

.avatar {
    --avatar-size: calc(var(--space-size) * 5);

    height: var(--avatar-size);
    width: var(--avatar-size);
    border-radius: 100%;
    border: 2px dashed var(--text-color-default);
    background-color: var(--bg-color-primary-alpha);
}

.avatarLoad {
    width: 100%;
    height: 100%;
    background: none;
    color: transparent;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
}

.avatarLoad > * {
    opacity: 0;
}

.headerContent {
}

.headerTitle {
    font-weight: bold;
}

.headerSubtitle {
    display: block;
    color: var(--text-color-mutted);
    margin-bottom: 1em;
}

.upload {
    border-color: var(--text-color-mutted);
}

.form {
    display: flex;
    flex-direction: column;
    gap: var(--space-size);
}

.form label {
    font-weight: 700;
    color: var(--text-color-default);
    margin-bottom: calc(var(--space-size) / 2);
}

.form input {
    color: var(--text-color-default);
}

.row {
    display: flex;
    min-width: 300px;
    gap: var(--space-size);
    max-width: var(--max-width);
}

.button {
    margin-left: auto;
}

@media screen and (max-width: 800px) {
    .row {
        flex-wrap: wrap;
    }
}
