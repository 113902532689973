.title {
    margin-bottom: 0;
}

.table {
    width: 100%;
}

.tableHead {
    color: var(--text-color-mutted);
}

.tableHead th {
    padding-bottom: calc(var(--row-spacing) * 2);
}

.tableHead th:last-child {
    text-align: right;
}

.tableHead th:first-child {
    min-width: var(--first-column-width);
}

.section .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: left;
    margin-top: 40px;
}

.sectionWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 250px;
    position: relative;
    margin-bottom: 10px;
}

.sectionWrapper svg {
    height: 212px;
}

.contentWrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-40%);
    left: 50%;
}

.contentWrapper span {
    font-family: Mulish;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #7f7f94;
    margin-right: 16px;
}

.numbers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -4px;
}

.row {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    color: #040b12;
    margin: 0 4px;
}

.topInfo {
    max-width: 567px;
    color: #fff;
    margin: 20px auto 0;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.setNumber {
    background: #212735;
    border-radius: 40px;
    padding: 8px 15px;
    max-width: 70px;
    text-align: center;
}

.controls button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #fff;
    outline: none;
}

.controls button svg {
    fill: #fff;
}

.controls button[disabled] svg path {
    fill: #57595c;
}

.controls button:hover,
.controls button:active,
.controls button:focus {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #fff;
}

.controls button[disabled],
.controls button[disabled]:hover,
.controls button[disabled]:active,
.controls button[disabled]:focus {
    background-color: transparent;
    border: none;
    cursor: not-allowed;
}

.currentRound {
    margin-top: 20px;
    flex: 0 1 100%;
}

.lotteryItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
}

.lotteryItem:hover {
    background-color: #31343d;
    transition: 0.3s;
    border-radius: 4px;
}

.top {
    font-family: Poppins;
    font-weight: 700;
    font-size: 18px;
    line-height: 31px;
    color: #ffffff;
    margin-bottom: 15px;
    cursor: default;
    padding: 0;
}

.top:hover {
    background-color: transparent;
}

.lotteryItem .round {
    width: 60px;
}

.lotteryItem .time {
    width: 200px;
}

.lotteryItem .total {
    width: 200px;
}
