
.detailsCol {
    display: flex;
    justify-content: flex-end;
    gap: calc(var(--space-size) / 2)
}

.details {
    display: flex;
    align-items: center;
    border-radius: var(--border-radius-lg);
}

.details svg {
    font-size: initial;
}
