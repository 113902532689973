.root {

    --border: 1px solid var(--ui-mutted-color);

    border-radius: var(--border-radius-lg);
    background-color: var(--bg-color-dark-accent);
    color: var(--text-color-default);
    margin-bottom: var(--row-spacing);
}

.table {
    width: 100%;
}

.head {
    border-bottom: var(--border);
}

.head td {
    padding: var(--row-spacing) 0;
}

.head td:first-child {
    width: var(--first-column-width);
    padding-left: var(--row-spacing);
}

.head td:last-child {
    padding-right: var(--row-spacing);
    text-align: right;
}

.content {
    padding: var(--row-spacing) var(--row-spacing) var(--content-padding);
}

.info {
    border-bottom: var(--border);
    padding: var(--row-spacing);
}

.infoRow {
    display: flex;
    margin: 0;
}

.infoRow span:last-child {
    font-weight: bold;
}

.icon {
    color: var(--text-color-mutted);
    min-width: 5ch;
}

.text {
    flex: 1;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    gap: var(--row-spacing);
}

