.root {

    --size: 94px;
    --blur: 168px;
    --index: -1;
    --left: auto;
    --right: auto;
    --top: auto;
    --bottom: auto;

    position: absolute;
    content: '';
    z-index: var(--index);
    width: var(--size);
    height: var(--size);
    filter: blur(var(--blur));
    background-color: var(--primary-theme-color);

    /*position*/
    left: var(--left);
    right: var(--right);
    top: var(--top);
    bottom: var(--bottom);
}
