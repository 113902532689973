.component {
    opacity: 0;
    transition: none;
    white-space: nowrap;
    color: var(--text-color-mutted);
    transition: 0.5s;
}

.component:hover {
    text-decoration: underline;
}

.visible {
    color: var(--text-color-mutted);
    opacity: 1;
    transition: 0.5s;
}
