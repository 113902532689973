.root {
  --content-padding: 20px;
  --row-spacing: 10px;
  --first-column-width: 8ex;

  border-radius: var(--border-radius-lg);
  background-color: var(--bg-color-primary);
  flex: 1 1 27%;
  display: flex;
  flex-direction: column;
  max-height: 670px;
  min-width: 300px;
}

.header {
  padding: var(--content-padding);
  border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
  background-color: var(--bg-color-dark-accent);
  color: var(--text-color-default);
}

.body {
  border-radius: 0 0 var(--border-radius-lg) var(--border-radius-lg);
  padding: var(--content-padding);
  overflow-y: auto;
}

.body::-webkit-scrollbar {
  width: 10px;
}

.body::-webkit-scrollbar-track {
  background-color: transparent;
}

.body::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #0fc0cb;
  border-radius: 4px;
}
