.root {
  color: var(--text-color-default);

  --ellipse-width: 125px;
  --ellipse-height: calc(var(--ellipse-width) / 2);

  --padding-vertical: calc(var(--space-size) * 4);
  --inner-padding-vertical: calc(var(--space-size) / 2);
  text-align: center;

  padding: var(--padding-vertical) var(--space-size);
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.title {
  text-align: inherit;
  position: relative;
}

.title.left {
  text-align: left;
}

.title.right {
  text-align: right;
}

.titleBackground,
.title::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

:is(.left, .right) .titleBackground,
.title::after {
  transform: translate(0, -50%);
}

.left .titleBackground,
.title::after {
  left: 0;
}

.right .titleBackground,
.title::after {
  right: 0;
  left: unset;
}

.title::after {
  content: '';
  display: block;
  width: var(--ellipse-width);
  height: var(--ellipse-height);
  border-radius: 100%;
  opacity: 0.7;
  background: var(--primary-theme-color);
  filter: blur(168px);
}

.title h2 {
  position: relative;
}

.container {
  max-width: 700px;
  margin: 0 auto;
}

.left .container {
  margin-left: 0;
}

.right .container {
  margin-right: 0;
}

.containerFluid {
  width: 100%;
}

.container,
.containerFluid {
  padding: var(--inner-padding-vertical) 0;
}

.center {
}

@media (max-width: 768px) {
  .title h2 {
    font-size: 24px !important;
  }
  .root {
    --padding-vertical: calc(var(--space-size) * 2);
  }
}
