.date span {
    color: var(--text-color-mutted);
}

.date span {
    color: var(--text-color-mutted);
}

.detailsCol {
    display: flex;
    justify-content: flex-end;
    gap: calc(var(--space-size) / 2)
}

.details {
    display: flex;
    align-items: center;
    border-radius: var(--border-radius-lg);
}

.details svg {
    font-size: initial;
}

@media screen  and (max-width: 1000px) {
    .date {
        flex-wrap: wrap;
        justify-content: center;
    }
}

