/* .component {
    background-color: var(--bg-color-2);
} */

.component {
  color: var(--text-color-mutted);
  max-width: 450px;
}


.headerNote {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-color-16);
}

.connectorList {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
}
.connectorItem {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.connectorBtn {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 106px;
  box-sizing: border-box;
  border-radius: 8px;
  border: none;
  box-shadow: none;
  width: 100%;
}

.connectorBtn:hover {
  border: 0;
  opacity: 0.6;
}

.connectorBtn:disabled {
  background-color: var(--bg-color-6) !important;
}

.connectorLogo {
  width: 32px;
  height: 32px;
}

.connectorName {
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
  color: var(--text-color-mutted);
}

.modalFooter {
  text-align: center;
  padding: 14px 0;
  color: var(--text-color-19);
}

.footerLink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 1em;
  font-size: 16px;
  font-weight: bold;
  color: var(--text-color-10);
  height: calc(var(--space-size) * 2);
  letter-spacing: 0.03em;
  background-color: var(--text-color-16);
}

.footerLink:hover{
  color: var(--text-color-8);
}

@media (max-width: 767px) {
  .component {
    max-width: calc(100% - 20px) !important;
  }

  .header {
    position: relative;
    padding: 20px;
  }

  .headerLabel {
    font-size: 24px;
    line-height: 34px;
  }

  .connectorList {
    padding: 0 20px 20px 20px;
  }

  .connectorBtn {
    height: 100px;
  }
}
