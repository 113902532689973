.root {
    display: grid;
    grid-template-columns: minmax(auto, var(--max-width)) auto;
    grid-column-gap: var(--space-size);
    grid-row-gap: var(--space-size);
}

.buttonContainer {
    display: flex;
    width: max-content;
    align-items: flex-end;
    padding-bottom: calc(var(--space-size) * 2 - 44px /* input height */);

}

.button {
    min-width: 150px;
}

@media screen and (max-width: 600px) {
    .root {
        grid-auto-flow: row;
        grid-template-columns: initial;
    }

    .buttonContainer {
        width: 100%;
    }

    .button {
        width: 100%;
    }
}
