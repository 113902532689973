.root {
    --padding-vertical: 8px;
    --padding-horisontal: 20px;

    font-weight: bold;
    padding: var(--padding-vertical) var(--padding-horisontal);
    height: auto;
    border: 1px solid var(--primary-theme-color);
    color: var(--text-color-default);
    background-color: var(--bg-color-primary-alpha);
    display: flex;
    justify-content: center;
    align-items: center;
}

.root:global.ant-btn {
    line-height: inherit;
    padding-top: var(--padding-vertical);
}

.default {
    background-color: var(--primary-theme-color);
}

.ghost {
    /*background-color: var(--bg-color-primary-alpha);*/
    color: var(--primary-theme-color);
}

a.ghost {
    padding-top: var(--padding-vertical);
}

.success {
    background-color: var(--color-1);
    border-color: var(--color-1);
    color: var(--text-color-default);
}

.muted {
    background-color: var(--ui-mutted-color);
    border-color: var(--text-color-mutted);
    color: var(--text-color-mutted);
}

.small {
    --padding-vertical: 4px;
    --padding-horisontal: 10px;
    font-size: 12px;
    min-width: 80px;
    line-height: 1.4;
}

.large {
    --padding-horisontal: 34px;
}

.fullWidth {
    width: 100%;
}

button.root[disabled],
button.root[disabled]:hover,
button.root[disabled]:focus,
button.root[disabled]:active {
    background-color: var(--ui-mutted-color);
    border-color: var(--text-color-mutted);
    opacity: 0.3;
    color: var(--text-color-default);
}

button.root.default[disabled] {
    background-color: var(--text-color-mutted);
}

.root:hover, .root:active, .root:focus {
    /*color: var(--text-color-mutted);*/
    /*background-color: var(--bg-color-11);*/
}
