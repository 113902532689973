.section {
  padding: var(--space-size) 0;
  margin: var(--space-size) 0;
  border-radius: var(--border-radius-lg);
  background-color: var(--bg-color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.check {
  display: flex;
  align-items: center;
  min-height: 190px;
}

.check img {
  height: 190px;
}

.title {
  line-height: 1;
}

.subTitle {
  color: var(--text-color-mutted);
}

.title:first-child {
  margin: 0;
}

.button {
  margin-top: calc(var(--space-size) * 1.5);
  min-width: 200px;
}

.button > span.buttonTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.content {
  margin: 0 16px;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .content {
    margin: 0 4px;
  }

  .check img {
    height: 60px;
  }
  .check {
    min-height: 150px;
  }
}
