.root {
    width: 100%;
    padding: var(--space-size);
    display: flex;
    background-color: var(--bg-color-primary);
    border-radius: var(--border-radius-lg);
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    z-index: 1;
    gap: var(--space-size);
}

.content {
    display: flex;
    gap: var(--space-size);
}

.avatar {
    background-color: var(--bg-color-primary-alpha);
}

.name {
    padding: 0 var(--space-size);
    display: flex;
    flex-direction: column;
}

.name p {
    color: var(--text-color-mutted);
    margin: 0;
}

.info {
    display: flex;
    flex-direction: column;
    padding: 0 var(--space-size);
    align-self: flex-end;
}

.info h3 {
    font-family: var(--secondary-font-family);
}

.money h3 {
    color: var(--color-1);
}

.wonTickets h3 {
    color: var(--text-color-accent);
}

.divider {
    width: 1px;
    display: block;
    background-color: var(--ui-mutted-color);
}
