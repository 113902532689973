.component {
    border: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    width: 100%;
}

.input {
    width: 100%;
    border: 0;
    margin: 0;
    display: block;
    min-width: 0;
    padding: 0 14px;
    background: none;
    height: 44px;
    color: var(--text-color-3);
    font-size: 16px;
    font-weight: 400;
    font-family: var(--primary-font-family);
    text-overflow: ellipsis;
}


.input::placeholder {
    color: var(--text-color-mutted);
}

.input:disabled {
    color: var(--text-color-16);
}

.noDropDownPadding {
    padding-left: 0;
}

.inputWrapper {
    --border-color: var(--ui-input-border-color);

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    background: var(--bg-color-primary);
    border: 2px solid var(--border-color);
    box-sizing: border-box;
    border-radius: 16px;
    position: relative;
    transition: border-color ease-in-out 0.2s;
}

.inputWrapper:focus-within {
    --border-color: var(--primary-theme-color);
    outline: none;
}

.suffix {
    position: absolute;
    right: 1em;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    color: var(--text-color-mutted);
}

.stakeAmount {
    display: inline-flex;
}

.stakeAmount svg {
    height: 21px;
}

.label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: var(--text-color-mutted);
    margin-bottom: 2px;
    font-family: var(--primary-font-family);
}

.dropDownLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    width: 106px;
    height: 31px;
    background: #4260A6;
    border-radius: 100px;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    color: var(--text-color-mutted);
    border: none;
}

.dropDownLabel:active,
.dropDownLabel:focus,
.dropDownLabel:hover,
.dropDownLabel:focus-visible {
    background: #4260A6;
    color: var(--text-color-mutted);
    border: none;
}
