.paragraph {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-size);
}

.root {
  margin-top: 80px;
  display: flex;
}

.info {
  color: var(--text-color-mutted);
  flex: 2;
  min-width: 330px;
  padding-right: 100px;
}

.info p {
  color: var(--text-color-mutted);
}

.info span {
  color: var(--text-color-default);
  font-weight: 600;
  margin-bottom: 15px;
  display: block;
}

.info ul li span {
  margin-bottom: 0px;
  display: initial;
}

.title {
  font-size: 24px;
  margin-bottom: var(--space-size);
  position: relative;
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: #795ced;
}

.titleBackground,
.title::after {
  position: absolute;
  top: -30px;
  left: 3px;
}

.subTitle {
  color: inherit;
  font-size: 18px;
  margin-bottom: var(--space-size);
}

.sample {
  flex: 1;
}

.card {
  /* display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 46px 64px 64px;
  column-gap: 20px;
  min-width: 330px; */
}

.empty {
  width: 100%;
}

.numbers {
  display: flex;
  justify-content: space-between;
}

.number {
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: var(--text-color-mutted);
  text-shadow: 0 0 10px var(--bg-color-4-1);
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.row {
  /* align-items: center; */
  display: flex;
}

.rowTitle {
  color: var(--text-color-6);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: var(--space-size);
}

@media (max-width: 1024px) {
  .root {
    display: inherit;
  }
  .info {
    padding-right: 0px;
  }
}
