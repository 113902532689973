.subtitle {
    font-weight: bold;
    margin-bottom: var(--modal-content-space);
    color: var(--text-color-mutted);
}

.winningNumbers {
    font-family: var(--secondary-font-family);
    font-weight: bold;
    line-height: 1.5;
    font-size: 24px;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: var(--ui-mutted-color);
    margin: var(--modal-content-space) 0;
    display: block;
}

.divider.topDivider {
    margin-top: calc(var(--modal-content-space) * -1);
}

.infoRow {
    display: flex;
    margin-bottom: var(--modal-content-space);
}

.infoRow span:last-child {
    font-weight: bold;
}

.icon {
    color: var(--text-color-mutted);
    min-width: 5ch;
}

.text {
    flex: 1;
}

.tickets {
    display: flex;
    flex-direction: column;
    gap: var(--modal-content-space);
}


.rowSubtitle {
    margin-bottom: calc(var(--modal-content-space) / 2);
}

.buttonIcon svg {
    margin-right: calc(var(--modal-content-space) / 2);
}
