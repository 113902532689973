.description {
  color: var(--text-color-mutted);
  position: relative;
  max-width: 500px;
  margin: auto;
}

.description:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -20px;
  width: 106px;
  height: 2px;
  left: calc(50% - 53px);
  background: var(--primary-theme-color);
  right: 50%;
}

.topPanel {
}

.sortBy {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.sortBy span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #b1b5c4;
  flex: 0 0 100%;
}

.sortBy a {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #fcfcfd;
  flex: 0 1 240px;
  padding: 0;
  border: 1px solid #000001;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 5px 10px;
}

.sortBy a svg {
  position: relative;
  left: -35px;
}

.search {
}

.tokens {
}

.tickets {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.ticket {
  background: #131520;
  border-radius: 8px;
  flex: 0 1 365px;
  border: 1px solid #795ced;
  margin: 10px;
}

.topInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 20px 20px 0;
}

.left {
  display: flex;
  align-items: center;
}

.new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 3px;
  width: 65px;
  height: 27px;
  background: rgba(144, 198, 100, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 10px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
}

.green {
  color: #90c664;
  border: 1px solid #90c664;
}

.yellow {
  color: #dfbe41;
  border: 1px solid #dfbe41;
}

.network {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  width: 93px;
  height: 27px;
  left: 366px;
  top: 1062px;
  background: rgba(117, 127, 162, 0.2);
  border: 1px solid #757fa2;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: auto;
}

.network svg {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.timer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px 4px 4px;
  position: relative;
  width: 93px;
  height: 27px;
  background: rgba(255, 64, 110, 0.1);
  border: 1px solid #ff406e;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgba(255, 64, 110, 0.46);
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}

.timer::before {
  content: '';
  position: absolute;
  display: block;
  height: 2px;
  width: 48px;
  top: 0;
  background: #ff406e;
  top: -22px;
  left: 23px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  margin-bottom: 20px;
  border-bottom: 1px solid #757fa21a;
}

.background {
  position: absolute;
  height: 31px;
  width: 311px;
  filter: blur(45px);
}

.topInfo {
}

.tokenImage {
  margin-right: 20px;
  position: relative;
}

.tokenImageBackground {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heroIcon {
  height: 28px;
  width: 28px;
}

.tokenTitle {
  margin: 0;
}

.mainInfo {
  padding: 0px 20px 20px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.rowTitle,
.rowInfo {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}

.bold {
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}

.playButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}

.playButton a {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.afterElement {
  content: '';
  display: block;
  position: absolute;
  bottom: -21px;
  width: 340px;
  height: 2px;
  left: -6px;
}

.playButton svg {
  margin-right: 10px;
}
