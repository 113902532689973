.paragraph {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-size);
}

.info {
  color: var(--text-color-mutted);
  flex: 2;
  padding-right: 80px;
}

.root {
  display: flex;
  margin-top: 100px;
}

.title {
  font-size: 24px;
  margin-bottom: var(--space-size);
  position: relative;
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: #795ced;
}

.titleBackground,
.title::after {
  position: absolute;
  top: -30px;
  left: 3px;
}

.subtitle {
  font-size: 20px;
  color: var(--text-color-default);
}

.sample {
  flex: 1;
}

.card {
  display: flex;
  flex-direction: column;
  min-width: 330px;
}

.graph {
  min-height: 100px;
  margin: 0 auto 40px;
}

.columnTitle {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: var(--text-color-6);
  font-family: var(--secondary-font-family);
  font-weight: bold;
}

.columnTitle small {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #757fa2;
  margin-bottom: 20px;
}

.stats {
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-auto-rows: max-content;
  row-gap: 4px;
}

.row {
  display: flex;
  gap: 4px;
  color: var(--text-color-mutted);
  font-size: 16px;
  margin-bottom: 15px;
  align-items: center;
}

.row span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: var(--text-color-default);
}

.circle {
  border-radius: 4px;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.value {
  font-family: var(--secondary-font-family);
  font-weight: bold;
  color: var(--text-color-default);
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: right;
}

.info span {
  color: var(--text-color-default);
  font-weight: 600;
  margin-bottom: 15px;
  display: block;
}

.info ul li span {
  margin-bottom: 0px;
  display: initial;
}

@media (max-width: 1024px) {
  .root {
    display: inherit;
  }
  .info {
    padding-right: 0px;
  }
}
